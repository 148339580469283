// CV10-020 차량 등록 관리 - Contents.tsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as S from './style/ButtonListStyle.style'; // 이중 버튼 스타일
import * as S2 from './style/SearchOptionSection.style'; // 검색 옵션 스타일
import './gridStyle.css';

import { read, utils } from 'xlsx'; // 라이브러리

import { loadExistingData } from '../utils/loadExistingData'; // 기존에 존재하는(=서버에 있는) 데이터 불러오기
import { checkDuplicateCarNum } from '../utils/checkDuplicateCarNum';

import { loadApartmentBlockList } from '../utils/loadApartmentBlockList'; // 동 리스트 불러오기
import { loadApartmentUnitList } from '../utils/loadApartmentUnitList'; // 호 리스트 불러오기

import { onEncodedString } from '../utils/onEncodedString'; // 문자열을 base64URL로 변환

import { Grid } from '../Grid/Grid';
import { gridOptions } from '../Grid/gridOptions';

import { BeforeResistGuideModal } from '../BeforeResistGuideModal/BeforeResistGuideModal';
import { BeforeInitialAlertModal } from '../BeforeInitialAlertModal/BeforeInitialAlertModal';
import { AddNewDataModal } from '../AddNewDataModal/AddNewDataModal';
import { PrivateSetModal } from '../PrivateSetModal/PrivateSetModal';

import { onApartmentSerialNumberList } from '../utils/onApartmentSerialNumberList'; // ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))
import { Modal } from '../../apartmentSerialNumberSelectModal/Modal'; // ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))

import { Button } from '../../UI/Button/Long';

import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './DatePicker.css';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

export const Contents = () => {
  const permitCode = sessionStorage.getItem('permitCode');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 관리소장 로그인시 아파트 계약 인덱스
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx'); // ref 있음 = 일반 인덱스, 아파트 고유 인덱스(계약 아님)
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  const [filteredNewAddedData, setFilteredNewAddedData] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);
  const [completelyExistentData, setCompletelyExistentData] = useState([]);
  const [onlyExistentServerData, setOnlyExistentServerData] = useState([]);

  const [loadedData, setLoadedData] = useState([]); // 기존에 존재하는(=서버에 있는) 데이터
  const [xlsxFileData, setXlsxFileData] = useState<any[]>([]);
  const [resultData, setResultData] = useState([]);
  const [resultData2, setResultData2] = useState([]);

  let processedAddedData = [];
  const addedData = []; // 새로 추가한 데이터
  const processedData = [];

  const [hasHandledFile, setHasHandledFile] = useState(false);

  const handleLocalFileAptIdxAlert = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
      return alert('단지를 선택후 다시 해주세요');
    }
  }

  const handleLocalFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasHandledFile(false);
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async e => {
        if (e.target && e.target.result) {
          const wb = read(new Uint8Array(e.target.result));
          const ws = wb.Sheets[wb.SheetNames[0]];
          const data = utils.sheet_to_json(ws);

          let dataStarted = false;
          const extractedInitialData = [];
          let rowData = {};

          for (const row of data) {
            //console.log('🍺🍺🍺row: ', row);
            if (!dataStarted) {
              if (
                row[Object.keys(row)[0]] === '차량번호' &&
                row['__EMPTY'] === '구분' &&
                row['__EMPTY_1'] === '만료일' &&
                row['__EMPTY_2'] === '소유주이름' &&
                row['__EMPTY_3'] === '연락처'
              ) {
                /**
                 * row[Object.keys(row)[0]]:
                 * - Object.keys() MDN: https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
                 * -
                 *
                 */
                dataStarted = true;
              }
            } else {
              // 데이터 처리 시작 이후의 행
              rowData = row;
              extractedInitialData.push(rowData);
            }
          }
          if (extractedInitialData.length == 0) {
            // \n 엔터 효과
            setOpenBeforeResistGuideModal(false);
            e.stopPropagation();
            return alert(
              '적절한 템플릿이 아닙니다. 엑셀 템플릿 사용안내를 참고하신 후, 규정에 맞는 파일로 다시 시도해주세요.'
            );
          }
          // 필요에 따라  extractedInitialData데이터를 가공하여 사용
          // onProcessingInitialData(extractedInitialData); <이 코드로 하니 한템포 전 데이터를 불러옴
          onProcessingInitialData(extractedInitialData, () => {
            // setXlsxFileData가 완료된 후에 호출
            controlBeforeResistGuideModal();
          });
        }
      };

      reader.readAsArrayBuffer(file);
      setHasHandledFile(true);
    }
  };


  function onProcessingInitialData(
    extractedInitialData,
    // callback
    controlBeforeResistGuideModal
  ) {
    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    function calExcelDate(date) {
      // 'YYYY-MM-DD' 형식으로 들어올 때
      if (/\d{4}-\d{2}-\d{2}/.test(date)) {
        return date;
      }

      // '109574'와 같이 숫자 6자리 형식으로 들어올 때
      const excelDate = date;
      const dateValue = new Date((excelDate - 25569) * 86400 * 1000);
      return formatDate(dateValue);
    }

    const resultData = [
      ...extractedInitialData.map(data => {
        return {
          CarNo: data[Object.keys(data)[0]] ? data[Object.keys(data)[0]] : '', // 차량번호
          CarFlag: data.__EMPTY ? data.__EMPTY : '', // 구분
          CarExpireDate: data.__EMPTY_1 ? calExcelDate(data.__EMPTY_1) : '', // 만료일
          OwnerName: data.__EMPTY_2 ? data.__EMPTY_2 : '', // 소유주이름
          OwnerPhone: data.__EMPTY_3 ? data.__EMPTY_3.replace(/-/g,"") : '', // 연락처
          Addr1: data.__EMPTY_4 ? data.__EMPTY_4 : '', // 동
          Addr2: data.__EMPTY_5 ? data.__EMPTY_5 : '', // 호
        };
      }),
    ];
    setXlsxFileData(resultData);

    // setXlsxFileData(resultData, () => {
    //   // setXlsxFileData가 완료된 후에 콜백 호출
    //   // callback();
    //   controlBeforeResistGuideModal();
    // });

    // 컴포넌트가 렌더링된 후 실행되는 부분
    // useEffect(() => {
    //   // setXlsxFileData가 완료된 후에 콜백 호출
    //   controlBeforeResistGuideModal();
    // }, [resultData]); // resultData가 변경될 때마다 실행
  }
  useEffect(() => {
    // setXlsxFileData가 완료된 후에 콜백 호출
    if (!hasHandledFile) {
      return;
    }
    controlBeforeResistGuideModal();
  }, [xlsxFileData]);

  // filteringAddedData()함수를 통해 새로 추가한 데이터를 필터링함
  function filteringAddedData(data, item) {
    // 👇 전화번호: '-' 문자열 제거, item = data.OwnerPhone
    if (item.includes('-')) {
      return data.OwnerPhone.replace(/-/g,"");
    }

    // 👇 동번호(Addr1): '동' 문자열 제거, item = data.Addr1
    if (item.includes('동')) {
      return data.Addr1.replaceAll('동', '');
    }

    // 👇 호수(Addr2): '호' 문자열 제거, item = data.Addr2
    if (item.includes('호')) {
      return data.Addr2.replaceAll('호', '');
    }

    /**
     * 공백 제거 정규표현식 적용 예시:
     * const stringWithSpaces = 'Hello    World    Example';
     * const stringWithoutSpaces = stringWithSpaces.replace(/\s+/g, '');
     * console.log(stringWithoutSpaces); // 'HelloWorldExample'
     */
    // 👇 글자 사이 공백 제거
    if (item) {
      return item.toString().replace(/\s+/g, '');
    } else if (!item) {
      return '';
    }
  }

  function onProcessingAddedDataData(addedData) {
    function onCarFlag(data: string) {
      if (data === '등록') {
        return 'R';
      } else if (data === '임시') {
        return 'T';
      } else if (data === '기간') {
        return 'D';
      }
      return data;
    }

    addedData = xlsxFileData.map(data => {
      return {
        // 👇 글자 사이 공백 제거
        // Addr1: data.Addr1.toString().replace(/\s+/g, ''),
        Addr1: filteringAddedData(data, data.Addr1.toString()),
        Addr2: filteringAddedData(data, data.Addr2.toString()),
        CarNo: data.CarNo.toString().replace(/\s+/g, ''),
        OwnerPhone: filteringAddedData(data, data.OwnerPhone.toString()),
        CarFlag: onCarFlag(data.CarFlag),
        CarExpireDate: !data.CarExpireDate  ? '' : data.CarExpireDate.toString().slice(0, 10).replace(/\s+/g, ''),
        OwnerName: data.OwnerName.toString().replace(/\s+/g, ''),
      };
    });

    processedAddedData = addedData;
  }

  function onFilteringData() {
    checkDuplicateCarNum({
      xlsxFileData,
      loadedData,
      processedAddedData,
      processedData,
      setFilteredNewAddedData,
      setModifiedData,
      setCompletelyExistentData,
      setOnlyExistentServerData,
      setResultData,
      setResultData2,
    });
  }

  if (processedAddedData) {
    addedData.push(...xlsxFileData);
    onProcessingAddedDataData(addedData);
  }

  const [openBeforeResistGuideModal, setOpenBeforeResistGuideModal] =
    useState(false);

  // controlBeforeResistGuideModal: 그리드 ✋✋✋✋✋
  const controlBeforeResistGuideModal = async () => {
    checkDuplicateCarNum({
      xlsxFileData,
      loadedData,
      processedAddedData,
      processedData,
      setFilteredNewAddedData,
      setModifiedData,
      setCompletelyExistentData,
      setOnlyExistentServerData,
      setResultData,
      setResultData2,
    });

    setOpenBeforeResistGuideModal(true);
  };

  const [openBeforeInitialAlertModal, setOpenBeforeInitialAlertModal] =
    useState(false);

  const controlBeforeInitialAlertModal = () => {
    if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
      return alert('단지를 선택해주세요');
    }

    setOpenBeforeInitialAlertModal(true);
  };

  // =========================================

  const [openAddNewDataModal, setOpenAddNewDataModal] = useState(false);

  const controlAddNewDataModal = () => {
    if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
      return alert('단지를 선택해주세요');
    }

    setOpenAddNewDataModal(true);
  };

  const [openPrivateSetModal, setOpenPrivateSetModal] = useState(false);

  const controlPrivateSetModal = () => {
    if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
      return alert('단지를 선택해주세요');
    }

    setOpenPrivateSetModal(true);
  };

  // =========================================
  // =========================================
  // [MEMO] onExportCSVFile(): CSV파일로 내보내기
  function onExportCSVFile() {
    if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
      return alert('단지를 선택해주세요');
    }
    gridOptions.api.exportDataAsCsv();
  }
  // =========================================
  // =========================================

  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API
  const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentName, setApartmentName] = useState('');

    const closeModal = () => {
    setOnModal(false);
  };

  // =========================================
  // =========================================

  // =========================================
  // =========================================
  // 👇 [TAG] 동, 호수 선택 -----start
  const [apartmentBlockListData, setApartmentBlockListData] = useState([]);
  const [apartmentUnitListData, setApartmentUnitListData] = useState([]);

  const [apartmentBlockNumber, setApartmentBlockNumber] = useState('');
  const [apartmentUnitNumber, setApartmentUnitNumber] = useState('');
  const [currentSelectedBlock, setCurrentSelectedBlock] = useState('');

  // console.log('CV10-020/Contents.tsx-apartmentBlockNumber', apartmentBlockNumber);
  // console.log('CV10-020/Contents.tsx-apartmentUnitNumber', apartmentUnitNumber);

  const handleApartmentBlockNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setApartmentBlockNumber(e.target.value);
  };

  const handleApartmentUnitNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setApartmentUnitNumber(e.target.value);
  };

  const apartmentBlockOptions = [
    { value: '', label: '동' },
    ...apartmentBlockListData.map(option => ({
      value: option.Addr,
      label: option.Addr,
    })),
  ];

  // const apartmentUnitOptions = [
  //   { value: '', label: '호' },
  //   ...apartmentUnitListData.map(
  //     option =>
  //       apartmentBlockNumber && {
  //         value: option.Addr,
  //         label: option.Addr,
  //       }
  //   ),
  // ];

  const apartmentUnitOptions = [
    { value: '', label: '호', block: currentSelectedBlock },
    ...(apartmentBlockNumber
      ? apartmentUnitListData.map(option => ({
          value: option.Addr,
          label: option.Addr,
          block: currentSelectedBlock,
        }))
      : []),
  ];
  // 👆 [TAG] 동, 호수 선택 -----end

  useEffect(() => {
    sessionStorage.setItem('savedEnteredName', onSavedEnteredName); // 🙏이거 있어야 새로고침할 때 소유주 인풋에 빈값으로 나옴
    sessionStorage.setItem('savedEnteredCarNumber', onSavedEnteredCarNumber); // 🙏이거 있어야 새로고침할 때 소유주 인풋에 빈값으로 나옴

    // if (apartmentIdx) {
    loadApartmentBlockList({
      apartmentIdx: Number(apartmentIdx),
      setApartmentBlockListData,
    });

    if (apartmentBlockListData) {
      loadApartmentUnitList({
        apartmentIdx: Number(apartmentIdx),
        apartmentBlockNumber,
        setApartmentUnitListData,
        apartmentUnitOptions,
        setCurrentSelectedBlock,
      });
    }
  }, [apartmentIdx, loadedData, apartmentBlockNumber, onSavedEnteredCarNumber]);
  // 👆 apartmentBlockNumber(호수 호출을 위해 필요)
  // 👆 onSavedEnteredCarNumber 인풋창에 데이터남기기 위해 필요

  // =========================================
  // =========================================
  // [TAG] 👇 만료일 -----start
  const [startDate, setStartDate] = useState(new Date()); // MEMO: Date Picker 라이브러리

  // 👇 데이터 피커에서 선택한 날짜
  const year = startDate.getFullYear().toString();
  const month = (startDate.getMonth() + 1).toString();
  const date = startDate.getDate().toString();

  const calYear = String(year).padStart(2, '0');
  const calMonth = String(month).padStart(2, '0');
  const calDate = String(date).padStart(2, '0');

  const selectedDate = `${calYear}${calMonth}${calDate}`;
  // [TAG] 👆 만료일 -----end

  // [TAG] 👇 특이사항 -----start
  // 특이사항 유무
  const [checkedValidSignificantFlag, setCheckedValidSignificantFlag] =
    useState(false);
  const [significantFlagSign, setSignificantFlagSign] = useState('');

  // 특이사항 유무
  function switchCheckedValidSignificantFlag() {
    if (checkedValidSignificantFlag === true) {
      setCheckedValidSignificantFlag(false);
      setSignificantFlagSign('');
    } else if (checkedValidSignificantFlag === false) {
      setCheckedValidSignificantFlag(true);
      setSignificantFlagSign('M');
    }
  }
  // [TAG] 👆 특이사항 -----end

  // [TAG] 👇 소유주 이름/연락처 -----start
  const [enteredName, setEnteredName] = useState('');
  const [onSavedEnteredName, setOnSavedEnteredName] = useState(''); // 🙏소유주 이름/연락처 (인풋에 남기기 위한)

  // [MEMO] onEnteredName: 일반 문자열을 BASE64로 인코딩
  const onEnteredName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });
    setEnteredName(encodedString);
    setOnSavedEnteredName(e.target.value);
  };
  // [TAG] 👆 소유주 이름/연락처 -----end

  // [TAG] 👇 차량 번호 -----start
  const [enteredCarNumber, setEnteredCarNumber] = useState('');
  const [onSavedEnteredCarNumber, setOnSavedEnteredCarNumber] = useState(''); // 🙏차량 번호 (인풋에 남기기 위한)

  // [MEMO] onEnteredName: 일반 문자열을 BASE64로 인코딩
  const onEnteredCarNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });
    setEnteredCarNumber(encodedString);
    setOnSavedEnteredCarNumber(e.target.value);
  };
  // [TAG] 👆 차량 번호 -----end

  function onPermitCodeView() {
    if (permitCode === apartmentManagerCode) {
      // 관리소장 권한시: 단지 고유 번호 부여
      useEffect(() => {
        setApartmentIdx(apartmentRefIdx);
      }, []);
    }

    useEffect(() => {
      if (apartmentIdx) {
        loadExistingData({
          setLoadedData,
          setLoading,
          apartmentIdx: Number(apartmentIdx),
          selectedDate,
          significantFlagSign,
          apartmentBlockNumber,
          apartmentUnitNumber,
          enteredCarNumber,
          enteredName,
          onSavedEnteredName, // 🙏
          onSavedEnteredCarNumber, // 🙏
        });
      }
      // }, [calYear, calMonth, apartmentIdx]);
    }, [selectedDate, apartmentIdx]);
  }
  onPermitCodeView();

  // [TAG] 👇 검색 옵션 -----start
  const [essentialCategory, setEssentialCategory] = useState(false);
  function onEssentialCategory() {
    setEssentialCategory(!essentialCategory);
  }
  // [TAG] 👆 검색 옵션 -----end

  // [TAG] 👇 엑셀 템플릿 가이드 -----start
  const [openTemplateGuide, setOpenTemplateGuide] = useState(false);
  function controlSetOpenTemplateGuide() {
    setOpenTemplateGuide(!openTemplateGuide);
  }
  // [TAG] 👆 엑셀 템플릿 가이드 -----end

  const moveToTemplatePage = () => {
    // URL 연결(템플릿 다운로드 링크)
    // window.location.href = `${process.env.SITE_URL}/data/RegistrationCAR.xlsx`;
    window.location.href = `/data/RegistrationCAR.xlsx`;
  };

  const [loading, setLoading] = useState(false); // [MEMO] 데이터 로딩

  const [detectChangingData, setDetectChangingData] = useState(false); // 데이터 변동시(추가, 수정, 삭제), 데이터 불러오기 위한 트리거

  useEffect(() => {
    if (apartmentIdx) {
      loadExistingData({
        setLoadedData,
        setLoading,
        apartmentIdx: Number(apartmentIdx),
        selectedDate,
        significantFlagSign,
        apartmentBlockNumber,
        apartmentUnitNumber,
        enteredCarNumber,
        enteredName,
        onSavedEnteredName, // 🙏
        onSavedEnteredCarNumber, // 🙏
      });
    }

    setDetectChangingData(false);
  }, [detectChangingData === true]);

  if (loading) {
    return (
      <>
        <Background>
          <LoadingWrap>
            <LoadingGIF src="/images/loading.gif" />
          </LoadingWrap>
        </Background>
      </>
    );
  }

  const savedEnteredName = sessionStorage.getItem('savedEnteredName'); // 🙏
  const savedEnteredCarNumber = sessionStorage.getItem('savedEnteredCarNumber'); // 🙏

  const pressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
        return alert('단지를 선택해주세요');
      }

      e.preventDefault(); // 기본 동작 중단
      console.log('Enter key pressed'); // 디버깅을 위한 로그

      return loadExistingData({
        setLoadedData,
        setLoading,
        apartmentIdx: Number(apartmentIdx),
        selectedDate,
        significantFlagSign,
        apartmentBlockNumber,
        apartmentUnitNumber,
        enteredCarNumber,
        enteredName,
        onSavedEnteredName, // 🙏
        onSavedEnteredCarNumber, // 🙏
      });
    }
  };

  // ①② ➊➋

  return (
    <>
      {permitCode === headOfficerManagerCode && onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListData={apartmentListData}
          setApartmentListData={setApartmentListData}
          apartmentIdx={apartmentIdx}
          setApartmentIdx={setApartmentIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}

      <Title>차량 등록 관리</Title>

      <Wrap>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '70%' }}>
            <S2.EssentialCategorySection
              className={essentialCategory ? 'on-option' : 'off-option'}
              onClick={() => onEssentialCategory()}
            >
              <S2.EssentialCategoryWrap
                className={essentialCategory ? 'on-option' : 'off-option'}
              >
                {essentialCategory ? (
                  <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ fontSize: 20 }} />
                )}
                검색 옵션 선택
              </S2.EssentialCategoryWrap>
            </S2.EssentialCategorySection>

            {/* 👇 검색 옵션 항목 */}
            {essentialCategory && (
              <S2.CategorySection>
                <S2.CategoryWrap>
                  <S2.CategoryBox>
                    <S2.CategoryName>소유주</S2.CategoryName>
                    <S2.InputStyle
                      placeholder="소유주 이름 혹은 연락처"
                      defaultValue={savedEnteredName ? savedEnteredName : ''}
                      onChange={onEnteredName}
                      onKeyPress={pressEnter}
                    />
                  </S2.CategoryBox>

                  <S2.CategoryBox>
                    <S2.CategoryName>차량 번호</S2.CategoryName>
                    <S2.InputStyle
                      placeholder="차량 번호"
                      defaultValue={
                        savedEnteredCarNumber ? savedEnteredCarNumber : ''
                      }
                      onChange={onEnteredCarNumber}
                      onKeyPress={pressEnter}
                    />
                  </S2.CategoryBox>

                  <div style={{ margin: '0 10px' }}>
                    <S2.CategoryName>동</S2.CategoryName>
                    <S2.SelectStyle
                      value={apartmentBlockNumber}
                      onChange={handleApartmentBlockNumber}
                    >
                      {apartmentBlockOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </S2.SelectStyle>
                  </div>

                  <div style={{ margin: '0 10px' }}>
                    <S2.CategoryName>호</S2.CategoryName>
                    <S2.SelectStyle
                      value={apartmentUnitNumber}
                      onChange={handleApartmentUnitNumber}
                    >
                      {apartmentUnitOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </S2.SelectStyle>
                  </div>

                  <div>
                    <input
                      style={{ marginLeft: '10px' }}
                      type="checkbox"
                      id={'check-valid-Significant'}
                      checked={checkedValidSignificantFlag}
                      onChange={() => switchCheckedValidSignificantFlag()}
                    />
                    <label
                      style={{ fontSize: '13px' }}
                      htmlFor={'check-valid-Significant'}
                    >
                      위반차량
                    </label>
                  </div>
                </S2.CategoryWrap>
              </S2.CategorySection>
            )}
            {/* 👆 검색 옵션 항목 */}
          </div>

          <div style={{ width: '30%' }}>
            <TemplateSection
              className={essentialCategory ? 'on-option' : 'off-option'}
              onClick={() => controlSetOpenTemplateGuide()}
            >
              <TemplateWrap
                className={openTemplateGuide ? 'on-option' : 'off-option'}
              >
                {openTemplateGuide ? (
                  <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ fontSize: 20 }} />
                )}
                엑셀 템플릿 사용안내
              </TemplateWrap>

              {openTemplateGuide && (
                <TemplateGuideBox>
                  <TemplateGuideText>
                    <p>
                      · <span>차량 정보 가져오기(엑셀)</span>를 이용할 경우
                      반드시 지정된 템플릿을 사용해주세요.
                    </p>
                    <p>
                      · 하단의 <span>템플릿 다운로드</span>를 눌러 양식을 받으신
                      후, 필요한 정보를 입력해주세요.
                    </p>
                    <p>
                      ·{' '}
                      <span className="warning">
                        템플릿에 기재된 항목을 임의로 변경해서는 안됩니다.
                      </span>
                    </p>
                  </TemplateGuideText>

                  <TemplateDownLoad
                    onClick={e => {
                      e.stopPropagation();
                      moveToTemplatePage();
                    }}
                  >
                    엑셀 템플릿 다운로드
                  </TemplateDownLoad>
                </TemplateGuideBox>
              )}
            </TemplateSection>
          </div>
        </div>

        <HeaderSection>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <DatePickerBox>
                <p>만료일</p>
                <div>
                  <DatePicker
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    onChange={(date: React.SetStateAction<any>) =>
                      setStartDate(date)
                    }
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </DatePickerBox>
            </div>

            {permitCode === headOfficerManagerCode && (
              <div style={{ marginLeft: '5px' }}>
                <Button
                  label="단지 선택"
                  onClick={() =>
                    onApartmentSerialNumberList({
                      apartmentListData,
                      setApartmentListData,
                      setOnModal,
                    })
                  }
                />
              </div>
            )}
            <div style={{ marginLeft: '5px', height: '100%' }}>
              <Button
                label="차량 정보 불러오기"
                onClick={() =>
                  loadExistingData({
                    setLoadedData,
                    setLoading,
                    apartmentIdx: Number(apartmentIdx),
                    selectedDate,
                    significantFlagSign,
                    apartmentBlockNumber,
                    apartmentUnitNumber,
                    enteredCarNumber,
                    enteredName,
                    onSavedEnteredName, // 🙏
                    onSavedEnteredCarNumber, // 🙏
                  })
                }
              />
            </div>

            <ApartmentNameBox>
              <ApartmentIdx style={{ marginRight: '5px' }}>
                {permitCode === headOfficerManagerCode && apartmentIdx}
              </ApartmentIdx>
              <div>{apartmentName}</div>
            </ApartmentNameBox>
          </div>

          {/* <div>
            <button onClick={() => console.log('xlsxFileData: ', xlsxFileData)}>
              xlsxFileData: 로컬에서 가져온 데이터 확인(로그)
            </button>
          </div>

          <div>
            <button onClick={() => console.log('loadedData: ', loadedData)}>
              loadedData: 서버에서 가져온 데이터 확인(로그)
            </button>
          </div>

          <div>
            <button onClick={() => onFilteringData()}>
              onFilteringData: 필터링한 이후의 데이터 확인(로그)
            </button>
          </div>

          <div>
            <button onClick={() => console.log('resultData: ', resultData)}>
              resultData: 최종 데이터 확인(로그)
            </button>
          </div> */}

          <div
            style={{
              display: 'flex',
              marginBottom: '5px',
            }}
          >
            <div>
              <S.InnerLineButtonStyle
                style={{
                  marginLeft: '5px',
                  width: '60px',
                }}
                onClick={() => controlBeforeInitialAlertModal()}
              >
                초기화
              </S.InnerLineButtonStyle>
              {openBeforeInitialAlertModal && (
                <BeforeInitialAlertModal
                  setOpenBeforeInitialAlertModal={
                    setOpenBeforeInitialAlertModal
                  }
                  apartmentIdx={Number(apartmentIdx)}
                  setDetectChangingData={setDetectChangingData}
                />
              )}
            </div>

            <div>
              <S.InnerLineButtonStyle
                style={{
                  marginLeft: '5px',
                  width: '115px',
                }}
                onClick={() => controlPrivateSetModal()}
              >
                개인정보 마스킹
              </S.InnerLineButtonStyle>
              {openPrivateSetModal && (
                <PrivateSetModal
                  setOpenPrivateSetModal={setOpenPrivateSetModal}
                  apartmentIdx={Number(apartmentIdx)}
                />
              )}
            </div>

            <div>
              <S.InnerLineButtonStyle
                style={{
                  marginLeft: '5px',
                  width: '80px',
                }}
                onClick={() => controlAddNewDataModal()}
              >
                개별 등록
              </S.InnerLineButtonStyle>
              {openAddNewDataModal && (
                <AddNewDataModal
                  setOpenAddNewDataModal={setOpenAddNewDataModal}
                  apartmentIdx={Number(apartmentIdx)}
                />
              )}
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              {/* 👇 임시 주석(수정 때문에) */}
              <S.LoadLocalLabelButtonStyle
                htmlFor="xlsx-file-road"
                style={{ marginLeft: '5px' }}
              >
                차량 정보 가져오기(엑셀)
                <div className="guide-modal">
                  <p className="bold">엑셀 파일 등록하기</p>
                  <p>
                    버튼을 눌러 엑셀 파일을 불러온 후, 차량 정보를 신규
                    등록하거나 동기화를 진행해주세요.
                  </p>
                </div>
              </S.LoadLocalLabelButtonStyle>
              { apartmentIdx ? (
                <input id="xlsx-file-road" type="file" accept=".xlsx" onChange={handleLocalFile} onClick={(event)=> { event.target.value=null}} style={{ display: 'none' }} />
              ):(
                <input id="xlsx-file-road" type="button" onClick={handleLocalFileAptIdxAlert} style={{ display: 'none' }} />
              )}
              <div>
                {openBeforeResistGuideModal && (
                  <BeforeResistGuideModal
                    setOpenBeforeResistGuideModal={
                      setOpenBeforeResistGuideModal
                    }
                    filteredNewAddedData={filteredNewAddedData}
                    modifiedData={modifiedData}
                    onlyExistentServerData={onlyExistentServerData}
                    resultData={resultData}
                    resultData2={resultData2}
                    completelyExistentData={completelyExistentData}
                    setLoadedData={setLoadedData}
                    setLoading={setLoading}
                    setDetectChangingData={setDetectChangingData}
                    apartmentIdx={Number(apartmentIdx)}
                  />
                )}
              </div>

              <S.InnerLineButtonStyle
                style={{ marginLeft: '5px' }}
                className="export-local-data"
                onClick={() => onExportCSVFile()}
              >
                차량 정보 내보내기(엑셀)
              </S.InnerLineButtonStyle>
            </div>
          </div>
        </HeaderSection>

        <LoadedDataSection className="custom-header-style">
          <Grid
            loading={loading}
            apartmentIdx={Number(apartmentIdx)}
            data={loadedData}
            setDetectChangingData={setDetectChangingData}
          />
        </LoadedDataSection>
      </Wrap>
    </>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  /* opacity: 50%; */
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF};

  /* opacity: 50%; */
`;

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  /* height: 40px;
  font-size: 13px; */
`;

const DatePickerBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  p {
    /* width: 100%; */
    margin-right: 5px;
  }
`;

// const SubTitle = styled.div`
//   ${({ theme }) => theme.contentsSubTitle};
//   ${({ theme }) => theme.stopDrag};

//   display: flex;
// `;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadedDataSection = styled.div`
  height: ${({ theme }) => theme.gridStyle.height};
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  cursor: pointer;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;

const TemplateSection = styled.div`
  ${({ theme }) => theme.stopDrag};

  margin-left: 5px;
  padding-right: 30px;
  padding-bottom: 15px;

  &.on-option {
    padding-bottom: 0;
  }
`;

const TemplateWrap = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  /* height: 100%; */
  padding-left: 10px;
  /* border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray}; */
  border: solid 1px #f7b70a;
  border-radius: 0.5em;
  background-color: #ffc936;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  &.on-option {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  :hover {
    /* color: ${({ theme }) => theme.mainColor.main}; */
    /* color: white; */
    background-color: #f7b70a;
  }
`;

const TemplateContents = styled.div`
  /* float: left; */
`;

const TemplateItem = styled.div`
  /* float: left; */
  margin: 5px 10px;
`;

const TemplateGuideBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 5px;
  width: 100%;
  height: 140px; /* 검색 옵션 높이와 일치해야 함 */

  border: solid 1px #f7b70a;
  border-top: none;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  font-size: 13px;
  line-height: 1.4;
`;

const TemplateGuideText = styled.div`
  color: ${({ theme }) => theme.fontColor.gray};
  span {
    font-weight: 600;
    /* color: #57d49e; */
    color: ${({ theme }) => theme.fontColor.black};
  }
  span.warning {
    color: red;
    font-weight: 400;
  }
`;

const TemplateDownLoad = styled.div`
  display: flex;
  justify-content: center;
  font-size: 13px;
  /* color: ${({ theme }) => theme.mainColor.main}; */
  background-color: #57d49e;
  border-radius: 0.5em;
  padding: 2px 0;
  margin-top: 10px;
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme.fontColor.black};

  &:hover {
    background-color: #49c48f;
    /* color: ${({ theme }) => theme.mainColor.dark}; */
    /* color: ${({ theme }) => theme.fontColor.lightGray}; */
  }
`;
