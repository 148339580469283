import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { onVerifiedCode } from '../utils/onVerifiedCode';
import { onChangePassword } from '../utils/onChangePassword';

import RouterTrackerForAnalytics from '../../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

import { Button } from '../../../components/UI/Button/Long';

const ChangePasswordPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '비밀번호 변경';
  RouterTrackerForAnalytics();

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  const [userName, setUserName] = useState('');
  const [regNum, setRegNum] = useState('');
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');

  const [verifiedCode, setVerifiedCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');

  const [explainPassword, setExplainPassword] = useState(false);
  // e: React.ChangeEvent<HTMLInputElement>
  // SetStateAction<number>

  const onChangeUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target.value.toString();
    setUserName(target);
  };

  const onChangeRegNum = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target.value.toString();
    setRegNum(target);
  };

  const onChangeCurrentPhoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target.value.toString();
    setCurrentPhoneNumber(target);
  };

  const onChangeVerifiedCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target.value.toString().trim();
    setVerifiedCode(target);
  };

  const onClickNewPassword = () => {
    setExplainPassword(true);
  };

  const onChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target.value.toString().trim();
    setNewPassword(target);
  };

  const onChangeCheckPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target.value.toString().trim();
    setCheckPassword(target);
  };

  // console.log(userName, 'userName');
  // console.log(currentPhoneNumber, 'changePhoneNumber');
  // console.log(verifiedCode, '인증번호');

  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(59);

  function onCountdown() {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }

  // if (token) {
  //   return navigate('/');
  // }

  return (
    <Section>
      <Wrap>
        <ItemBox>
          <ItemName>단지번호(6자리)</ItemName>
          <Input
            onChange={e => onChangeUserName(e)}
            type="text"
            placeholder="단지번호(6자리)를 입력하세요."
          />
        </ItemBox>

        <ItemBox>
          <ItemName>관리자 휴대폰 번호</ItemName>
          <div style={{ position: 'relative' }}>
            <Input
              onChange={e => onChangeCurrentPhoneNumber(e)}
              type="text"
              placeholder="휴대폰 번호"
            />
            <InsideButton
              onClick={() => {
                onVerifiedCode({ userName, currentPhoneNumber, onCountdown });
              }}
            >
              인증번호 받기
            </InsideButton>
          </div>
        </ItemBox>

        <ItemBox>
          <ItemName>인증번호</ItemName>
          <div style={{ position: 'relative' }}>
            <Input
              onChange={e => onChangeVerifiedCode(e)}
              type="text"
              placeholder="인증번호 입력"
            />
            <CountdownNum>
              {/* {minutes}:{seconds < 10 ? `0${seconds}` : seconds} */}
              {/* 00:00 */}
            </CountdownNum>
          </div>
        </ItemBox>

        <ItemBox>
          <ItemName>새 비밀번호</ItemName>
          <Input
            onClick={() => onClickNewPassword()}
            onChange={e => onChangeNewPassword(e)}
            type="password"
            placeholder="새 비밀번호"
          />
          {explainPassword && (
            <ExplainBox>
              <ExplainText className="first-text">
                · 6자 이상 15자 미만이어야 합니다.
              </ExplainText>
              <ExplainText>
                · 영문, 숫자 혼합으로 구성되어야 합니다.
              </ExplainText>
            </ExplainBox>
          )}
        </ItemBox>

        <ItemBox>
          <ItemName>비밀번호 확인</ItemName>
          <Input
            onChange={e => onChangeCheckPassword(e)}
            type="password"
            placeholder="비밀번호 확인"
          />
        </ItemBox>

        <ButtonSection>
          <div>
            <Button
              onClick={() =>
                onChangePassword({
                  userName,
                  regNum,
                  currentPhoneNumber,
                  verifiedCode,
                  newPassword,
                  checkPassword,
                })
              }
              label="비밀번호 변경"
            />
          </div>
          <div style={{ marginLeft: '5px' }}>
            <Button
              onClick={() => {
                window.close();
              }}
              label="현재화면 닫기"
            />
          </div>
        </ButtonSection>
      </Wrap>
    </Section>
  );
};

export default ChangePasswordPage;

const Section = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 450px;
  padding: 50px 30px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  border-radius: 0.5em;
`;

const ItemBox = styled.div`
  margin: 10px 0;
  width: 100%;
`;

const ItemName = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 14px;
  font-weight: 500;
`;

const Input = styled.input`
  display: inline-block;
  background-color: white;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-radius: 0.5em;
  color: ${({ theme }) => theme.fontColor.black};

  padding: 10px 15px;
  width: 100%;

  ::placeholder {
    color: #a0a0a0;
  }
`;

const InsideButton = styled.button`
  position: absolute;
  top: 50%;
  right: 15px;
  padding: 5px;
  background-color: #ededed;
  border-radius: 0.5em;
  border: solid 1px #c1c1c1;
  color: #717171;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  cursor: pointer;
`;

const CountdownNum = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 13px;
`;

const ExplainBox = styled.div`
  color: ${({ theme }) => theme.fontColor.gray};
  font-size: 13px;
`;

const ExplainText = styled.div`
  margin-top: 5px;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
`;
