import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";

interface onVerifiedCodeProps {
  userName: string;
  currentPhoneNumber: string;
  oldPhoneNumber?: string;
  onCountdown?: () => void;

  // recordListData: any[];
  // setRecordListData: Dispatch<SetStateAction<any[]>>;
  // setLoading: Dispatch<SetStateAction<boolean>>;
}

export const onVerifiedCode = async ({
  userName,
  currentPhoneNumber,
  oldPhoneNumber,
  onCountdown,
}: onVerifiedCodeProps) => {
  const oldNumber = oldPhoneNumber ? `&oldnumber=${oldPhoneNumber}` : '';

  if (!userName) {
    return alert('이름을 입력해주세요');
  } else if (!currentPhoneNumber) {
    return alert('휴대폰 번호를 입력해주세요');
  }

  await axios
    .post(
      // https://apihr.mmigroup.co.kr:9020/common/logincheck/requestauth?lang=ko&corpId=MM&oldnumber=99999
      `${process.env.COMMON_URL}/common/logincheck/requestauth?lang=ko&corpId=MM${oldNumber}`,
      {
        username: `${userName}`,
        number: `${currentPhoneNumber}`,
      }
    )
    .then(res => {1
      console.log(
        '인증번호 요청 - API확인',
        `${process.env.COMMON_URL}/common/logincheck/requestauth?lang=ko&corpId=MM${oldNumber}`
      );
      try {
        //console.log(res.data, 'res.data');
        //console.log(res.data.Data, 'res.data.Data');
        if (res.data.ErrorCode === 0) {
          if(res.data.Data[0].SendAuthMessage === true) {
            return alert('인증번호가 정상적으로 발송되었습니다\n\n문자 메시지 및 카카오 알림톡을 확인해주세요.');
          } else {
            Sentry.captureMessage(`인증번호 발송 실패 :  ${res.data.Data[0].ResultMessage}`);
            return alert(`인증번호 발송에 실패했습니다.\n\n- ${res.data.Data[0].ResultMessage}`);
          }
        } else {
          Sentry.captureMessage(`전화번호 변경 실패 : ${res.data.ErrorMsg} - ErrorCode [${res.data.ErrorCode}]`);
          return alert(`전화번호 변경 실패\n\n-${res.data.ErrorMsg} - ErrorCode [${res.data.ErrorCode}]`);
        }
      } catch (e) {
        Sentry.captureMessage(`인증번호 발송 실패 : ${res.data.ErrorMsg} - ErrCode [${res.data.ErrorCode}]`);
        Sentry.captureException(e);
        //console.error(e);
        //console.log(`ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`);
        return alert(`인증번호 발송에 실패했습니다.2\n\n- ${res.data.ErrorMsg} - ErrCode [${res.data.ErrorCode}]`);
      }
    });
};
