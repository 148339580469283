export function CorpLogo(mode : string) {
  if (mode=="DefaultSLogo"){
    return `${process.env.COMMON_URL}/common/logodownload?devicetype=W&logotype=2`;
  } else if (mode=="DefaultName"){
      return `(주)웰텍연구소`;
  } else if (mode=="Logo") {
    switch (window.location.origin){
      case process.env.SITE_URL_MMI :
        return `${process.env.COMMON_URL}/common/logodownload?corpid=MM&devicetype=W&logotype=1`;
      default :
        return `${process.env.COMMON_URL}/common/logodownload?devicetype=W&logotype=1`;
    }
  } else if (mode=="SLogo") {
    switch (window.location.origin){
      case process.env.SITE_URL_MMI :
        return `${process.env.COMMON_URL}/common/logodownload?corpid=MM&devicetype=W&logotype=2`;
      default :
        return `${process.env.COMMON_URL}/common/logodownload?devicetype=W&logotype=2`;
    }
  } else if (mode=="ICON") {
    switch (window.location.origin){
      case process.env.SITE_URL_MMI :
        return `${process.env.COMMON_URL}/common/logodownload?corpid=MM&devicetype=W&logotype=3`;
      default :
        return `${process.env.COMMON_URL}/common/logodownload?devicetype=W&logotype=3`;
    }
  } else if (mode=="SName") {
    switch (window.location.origin) {
    case process.env.SITE_URL_MMI :
      return `앰앰아이`;
    case process.env.SITE_URL_TEST :
      return `개발용`;
    case process.env.SITE_URL_WELLTECH_DEMO :
      return `웰텍연구소(DEMO)`;
    case process.env.SITE_URL_WELLTECH_DEV :
      return `웰텍연구소(DEV)`;
    default :
      return `웰텍연구소`;
    }
  } else if (mode=="Name") {
    switch (window.location.origin) {
    case process.env.SITE_URL_MMI :
      return `(주)앰앰아이`;
    case process.env.SITE_URL_TEST :
      return `개발용`;
    case process.env.SITE_URL_WELLTECH_DEMO :
      return `(주)웰텍연구소(DEMO)`;
    case process.env.SITE_URL_WELLTECH_DEV :
      return `(주)웰텍연구소(DEV)`;
    default :
      return `(주)웰텍연구소`;
    }
  }
};
