import React, { useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { ModalContents } from './ModifyContents';

interface ModifyModalProps {
  closeModal: () => void;
  detailWorkData: any;
  employeeNum: string;
  targetWorkStatusIdx: string | null;
  setRightClickModalVisible: Dispatch<SetStateAction<boolean>>;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;

  selectedStatusIdx: string; // 🔰
  setSelectedStatusIdx: Dispatch<SetStateAction<string>>; // 🔰
  cellData: any; // 🔰
}

export const ModifyModal = ({
  closeModal,
  detailWorkData,
  employeeNum,
  targetWorkStatusIdx,
  setRightClickModalVisible,
  setIsUpdateStatus,

  selectedStatusIdx, // 🔰
  setSelectedStatusIdx, // 🔰
  cellData, // 🔰
}: ModifyModalProps) => {
  useEffect(() => {
    setRightClickModalVisible(false);
  }, []);

  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents
          detailWorkData={detailWorkData}
          employeeNum={employeeNum}
          targetWorkStatusIdx={targetWorkStatusIdx}
          closeModal={closeModal}
          setIsUpdateStatus={setIsUpdateStatus}
          selectedStatusIdx={selectedStatusIdx} // 🔰
          setSelectedStatusIdx={setSelectedStatusIdx} // 🔰
          cellData={cellData} // 🔰
        />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
