import styled from 'styled-components';

export const Bar = styled.div`
  width: 100%;
  margin: 20px 0;
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.gray};
`;

export const Section = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    &.login-button {
      margin-top: 10px;
    }
  }
`;

export const Wrap = styled.div`
display:flex
flex-direction: column;
`;

export const WrapForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding: 50px 50px 50px 50px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  border-radius: 0.5em;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.fontColor.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: bold;
`;

export const Body = styled.div`
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 13px;
  font-weight: 500;
  align-items: left;
  line-height:1.5;
  width: 100%;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemBox = styled.div`
  margin: 10px 0;

  &. password-box {
    margin-top: 100px;
  }
`;

export const ItemName = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 14px;
  font-weight: 500;
`;

export const FindInfoText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.fontColor.lightGray};
  cursor: pointer;

  &:link {
    color: pink;
  }

  &:visited {
    color: pink;
  }

  &:hover {
    color: ${({ theme }) => theme.mainColor.main};
  }

  &:active {
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

export const UpdateInfo = styled.div`
  margin-top: 5px;
  text-align: end;
  /* color: ${({ theme }) => theme.fontColor.lightGray}; */
  color: #c1c1c1;
  font-size: 12px;
  font-weight: 400;
`;

export const ButtonWrap = styled.div`
  display: flex;
`;

export const ChangeBtn = styled.button`
  ${({ theme }) => theme.printBtnStyle};
  padding: 5px;

  p {
    color: #300FFF;
    font-weight: blod;
    width: 100px;
  }

  &.right-button {
    margin-left: 5px;
  }
`;

export const ImageWrap = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;