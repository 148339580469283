import React from 'react';
import styled from 'styled-components';
import { ModalContents } from './ModalContents';


interface WorkRecordModalProps {
  closeModal: React.MouseEventHandler<HTMLDivElement> | undefined;
  workRecordData: any;
}

export const WorkRecordModal = ({
  closeModal,
  workRecordData,
}: WorkRecordModalProps) => {
  // if (workRecordData.length <= 0) {
  //   return alert('태그 기록이 없습니다');
  // }

  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents workRecordData={workRecordData}/>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
