import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './Navbar.style';

import MenuIcon from '@mui/icons-material/Menu';

import { LogoutBtn } from '../UI/LogoutBtn/LogoutBtn';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CorpLogo } from '../../lib/utils/logoname';

export interface NavbarProps {
  isSidebarOpen: boolean;
  handleSidebar: () => void;
}

export const Navbar = ({ isSidebarOpen, handleSidebar }: NavbarProps) => {
  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */
  const navigate = useNavigate();
  const name = sessionStorage.getItem('userNameKR');
  const permitCode = sessionStorage.getItem('permitCode');
  const managingApartment = sessionStorage.getItem('apartmentName');

  if (location.pathname === '/login') return null;

  const kakaotalkQuestionURL = 'https://pf.kakao.com/_xhVgUG/chat';

  // if (process.env.NODE_ENV === 'development') {
  //   return (manualLink = 'https://hrdev.mmigroup.co.kr/manual/mmimanual.pdf');
  // } else if (process.env.NODE_ENV === 'production') {
  //   return (manualLink = 'https://hr.mmigroup.co.kr/manual/mmimanual.pdf');
  // }

  return (
    <S.Section className="exist toggle">
      <S.Wrap>
        <S.ToggleSection onClick={handleSidebar}>
          <MenuIcon
            sx={{
              color: 'black',
              // fontSize: '25px'
            }}
          />
        </S.ToggleSection>

        <S.TitleSection>
          <S.CompanyInfoSection>
            <p onClick={() => {navigate('/'); }} >
              <S.Logo src={CorpLogo("ICON")} alt={CorpLogo("Name")}/> WT 스마트 근무
            </p>
          </S.CompanyInfoSection>
        </S.TitleSection>
        <S.UserSection>
          {/* <S.ManualSection
            onClick={() => {
              window.open('https://pf.kakao.com/_xhVgUG/chat');
            }}
          >
            카카오채널
          </S.ManualSection> */}
          <a href="#" onClick={() => {window.open(kakaotalkQuestionURL);}}>
            <img src="/images/kakaotalk-question.png" height="35"></img>
          </a>
          <S.ManualSection
            onClick={() => {
              window.open(
                process.env.NODE_ENV === 'production' ? 'https://doc.welltechlab.co.kr/apps/pdf/?id=web' : 'https://doc.welltechlab.co.kr/apps/pdf/?id=web'
              );
            }}
          >
            {/* 사용설명서 */}
            <PictureAsPdfIcon
              sx={{
                color: 'black',
                fontSize: '28x',
              }}
            />
          </S.ManualSection>

          <S.UserInfoSection>
            {(permitCode === process.env.HEAD_OFFICE_MANAGER_CODE ||
              permitCode === process.env.EMPLOYEE_CODE) && (
              <>
                <p className="user-name">{name ? name : '관리자'}</p>님
              </>
            )}
            {permitCode === process.env.APARTMENT_MANAGER_CODE && (
              <>
                <p className="apartment-name">
                  {managingApartment ? managingApartment : '관리자'}
                </p>
              </>
            )}
          </S.UserInfoSection>
          <LogoutBtn />
        </S.UserSection>
      </S.Wrap>
    </S.Section>
  );
};
