import React from 'react';
import styled from 'styled-components';

// import { Mainbar } from '../../components/Mainbar/Mainbar';
import { Navbar } from '../../components/Navbar/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Main } from '../../components/DA10-010-main/Main/Main';

import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics';
import { CorpLogo } from '../../lib/utils/logoname';

const MainPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = CorpLogo("SName") + ' 스마트 근태';
  RouterTrackerForAnalytics();

  return (
    <>
      {/* <Mainbar /> */}

      {/* 👇 1. 네브바 */}
      {/* <Navbar /> */}
      {/* 👆 1. 네브바 */}

      {/* <Body> */}
      {/* 👇 2. 사이드바 */}
      {/* <Sidebar /> */}
      {/* 👆 2. 사이드바 */}
      {/* <Contents> */}
      {/* <Wrap> */}
      {/* 👇 3. 페이지 */}
      <Main />
      {/* 👆 3. 페이지 */}
      {/* </Wrap>
        </Contents>
      </Body> */}
    </>
  );
};

export default MainPage;

const Body = styled.div`
  display: flex;
  margin-top: 55px; /* 네브바의 height 감안 */
  /* height: 100vh; */
`;

const Contents = styled.div`
  margin-left: 200px; /* 사이드바의 width 감안 */
  width: 100vw;
  height: calc(100vh - 55px); /* 네브바의 height 감안 */
  /* background-color: orange; */
  background-color: ${({ theme }) => theme.backgroundColor.gray};
`;

const Wrap = styled.div`
  padding: 15px;
  /* background-color: pink; */
  background-color: ${({ theme }) => theme.backgroundColor.gray};
`;
