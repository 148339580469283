import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface onLoadWorkRecordInfoProps {
  employeeNum: string ;
  apartmentContractIdx: string;
  date: string;
  workRecordData: any[];
  setWorkRecordData: Dispatch<SetStateAction<never[]>>;
  setOnWorkRecordModal: Dispatch<SetStateAction<boolean>>;
}

export const onLoadWorkRecordInfo = async ({
  employeeNum,
  apartmentContractIdx,
  date,
  workRecordData,
  setWorkRecordData,
  setOnWorkRecordModal,
}: onLoadWorkRecordInfoProps) => {
  const token = sessionStorage.getItem('token');
  const cellDate = new Date(date);
  //console.log('Tag - date: ', date);
  //console.log('Tag - cellDate: ', cellDate.getFullYear());
  //console.log('Tag - cellDate: ', cellDate.getMonth()+1);
  //console.log('Tag - cellDate: ', cellDate.getDate());
  //console.log('Tag - employeeNum: ', employeeNum);
  //console.log('Tag - apartmentContractIdx: ', apartmentContractIdx);
  await axios
    .get(
      // 'https://apihr.mmigroup.co.kr:9020/attendance/ac10030/
      // attendancerecord?ItemPerPage=0&ItemStartPage=0&ContractIdx=257&year=2023&month=5&day=23&employeeno=111111'
      // ✅ 목데이터 (단지 계약 정보)
      // https://apihr.mmigroup.co.kr:9020/attendance/ac10030/attendancerecord?ItemPerPage=0&ItemStartPage=0&ContractIdx=2&year=2023&month=10&day=26&employeeno=731265&group=A
      //`${process.env.COMMON_URL}/attendance/ac10030/attendancerecord?ItemPerPage=0&ItemStartPage=0&ContractIdx=${apartmentContractIdx}&year=${calYear}&month=${calMonth}&day=${calDate}&employeeno=${employeeNum}&group=A`,
      `${process.env.COMMON_URL}/attendance/ac10030/attendancerecord?ItemPerPage=0&ItemStartPage=0&ContractIdx=${apartmentContractIdx}&year=${cellDate.getFullYear()}&month=${cellDate.getMonth()+1}&day=${cellDate.getDate()}&employeeno=${employeeNum}&group=A`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '[AC10-030]주간 근무-근무 기록(태그 기록)(=그리드 클릭시 나타나는 모달) API: ',
      //   `${process.env.COMMON_URL}/attendance/ac10030/attendancerecord?ItemPerPage=0&ItemStartPage=0&ContractIdx=${apartmentContractIdx}&year=${calYear}&month=${calMonth}&day=${calDate}&employeeno=${employeeNum}`
      // );

      try {
        if (res.data.Data)
        {
          setWorkRecordData(res.data.Data);

          if (res.data.Data.length > 0) {
            // return setOnWorkRecordModal(true); // [MEMO] 주석 해제시, 좌클릭 더블클릭으로도 수정 모달 나타남
          }
        }
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
