import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onWorkStatusLoadBtnDailyProps {
  setEmployeeDataDaily: Dispatch<SetStateAction<any[]>>;
  apartmentContractIdx: string; // 아파트 계약 인덱스
  calYear: string;
  calMonth: string;
  calDate: string;
  setDailySchedulerLoading: Dispatch<SetStateAction<boolean>>;
}

export const onWorkStatusLoadBtnDaily = async ({
  setEmployeeDataDaily,
  apartmentContractIdx, // 아파트 계약 인덱스
  calYear,
  calMonth,
  calDate,
  setDailySchedulerLoading,
}: onWorkStatusLoadBtnDailyProps) => {
  const token = sessionStorage.getItem('token');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 아파트 계약 인덱스

  if (!apartmentContractIdx) {
    return alert('단지를 선택해주세요');
  }

  setDailySchedulerLoading(true);

  await axios
    .get(
      // `${process.env.COMMON_URL}/attendance/ac10030/attendancestatus/${apartmentContractIdx}?Year=${calYear}&Month=${calMonth}&Day=${calDate}`
      // `/sample/AC10-030-daily-sample.json`, // ✅ 목데이터 (2023-03-01, idx: 14)
      `${process.env.COMMON_URL}/attendance/ac10030/attendancestatus/${apartmentContractIdx}?Year=${calYear}&Month=${calMonth}&Day=${calDate}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      console.log('onWorkStatusLoadBtnDaily(일간 근무현황 API확인): ',`${process.env.COMMON_URL}/attendance/ac10030/attendancestatus/${apartmentContractIdx}?Year=${calYear}&Month=${calMonth}&Day=${calDate}`);
      try {
        if (res.data.ErrorCode === 0) {
          setDailySchedulerLoading(false);
          setEmployeeDataDaily(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
