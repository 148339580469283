// CV10-030 차량 주차 관리 - Contents.tsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Grid } from '../Grid/Grid';
import { gridOptionsOfficeManager } from '../Grid/gridOptionsOfficeManager';
import { gridOptionsApartmentManager } from '../Grid/gridOptionsApartmentManager';

import { loadExistingData } from '../utils/loadExistingData';
import { loadApartmentBlockList } from '../utils/loadApartmentBlockList'; // 동 리스트 불러오기
import { loadApartmentUnitList } from '../utils/loadApartmentUnitList'; // 호 리스트 불러오기

import { onApartmentSerialNumberList } from '../utils/onApartmentSerialNumberList'; // ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))
import { Modal } from '../../apartmentSerialNumberSelectModal/Modal'; // ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))

import { Button } from '../../UI/Button/Long';
import { onEncodedString } from '../utils/onEncodedString'; // 문자열을 base64URL로 변환

import { ko } from 'date-fns/esm/locale';
import { addDays, subDays } from 'date-fns';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './DatePicker.css';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

export const Contents = () => {
  const navigate = useNavigate();
  const [imageText, setImageText] = useState('');

  const permitCode = sessionStorage.getItem('permitCode');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 관리소장 로그인시 아파트 계약 인덱스
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(false); // [MEMO] 데이터 로딩

  // 😎😎😎😎😎
  const dataFoo = loadedData.filter(d => d.AttImgSvrFile);
  console.log('👀👀👀사진있는 data: ', dataFoo);
  // 😎😎😎😎😎

  // 👇 [TAG] 단지 인덱스 불러오기 -----start
  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 리스트가 있는 모달
  const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 정보 API
  const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentName, setApartmentName] = useState('');

  const closeModal = () => {
    setOnModal(false);
  };
  // 👆 [TAG] 단지 인덱스 불러오기 -----end

  // 👇 [TAG] 데이터 피커 관련-----start
  const [startDate, setStartDate] = useState(new Date()); // MEMO: Date Picker 라이브러리
  const [endDate, setEndDate] = useState(new Date()); // MEMO: Date Picker 라이브러리

  // 👇 데이터 피커에서 선택한 날짜 (시작일)
  const pickStartYear = startDate.getFullYear().toString();
  const pickStartMonth = (startDate.getMonth() + 1).toString();
  const pickStartDate = startDate.getDate().toString();

  const calStartYear = String(pickStartYear).padStart(2, '0');
  const calStartMonth = String(pickStartMonth).padStart(2, '0');
  const calStartDate = String(pickStartDate).padStart(2, '0');

  // 👇 데이터 피커에서 선택한 날짜 (종료일)
  const pickEndYear = endDate.getFullYear().toString();
  const pickEndMonth = (endDate.getMonth() + 1).toString();
  const pickEndDate = endDate.getDate().toString();

  const calEndYear = String(pickEndYear).padStart(2, '0');
  const calEndMonth = String(pickEndMonth).padStart(2, '0');
  const calEndDate = String(pickEndDate).padStart(2, '0');

  const selectedContractStart = `${calStartYear}${calStartMonth}${calStartDate}`;
  const selectedContractEnd = `${calEndYear}${calEndMonth}${calEndDate}`;

  // 👆 [TAG] 데이터 피커 관련-----end

  // 👇 [TAG] 위반차량 여부-----start
  const [checkedValidSignificantFlag, setCheckedValidSignificantFlag] =
    useState(false);
  const [significantFlagSign, setSignificantFlagSign] = useState('');

  function switchCheckedValidSignificantFlag() {
    if (checkedValidSignificantFlag === true) {
      setCheckedValidSignificantFlag(false);
      setSignificantFlagSign('');
    } else if (checkedValidSignificantFlag === false) {
      setCheckedValidSignificantFlag(true);
      setSignificantFlagSign('M');
    }
  }
  // 👆 [TAG] 위반차량 여부-----end

  // 👇 [TAG] 미등록차량 여부-----start
  const [checkedUnregisteredCar, setCheckedUnregisteredCar] = useState(false);
  const [unregisteredCarSign, setUnregisteredCarSign] = useState('');

  function switchCheckedUnregisteredCar() {
    if (checkedUnregisteredCar === true) {
      setCheckedUnregisteredCar(false);
      setUnregisteredCarSign('');
    } else if (checkedUnregisteredCar === false) {
      setCheckedUnregisteredCar(true);
      setUnregisteredCarSign('N');
    }
  }
  // 👆 [TAG] 미등록차량 여부-----end

  // 👇 [TAG] 차량 번호 -----start
  const [enteredCarNumber, setEnteredCarNumber] = useState('');
  const [onSavedEnteredCarNumberCV103, setOnSavedEnteredCarNumberCV103] =
    useState(''); // 🙏차량 번호(돌아가기로 페이지 전환 후에도 기록이 남도록)
  const [onSavedViewCarNumberCV103, setOnSavedViewCarNumberCV103] =
    useState(''); // 화면에 보이는 부분

  // [MEMO] onEnteredName: 일반 문자열을 BASE64로 인코딩
  const onEnteredCarNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const encodedString = Buffer.from(zooZoo111)
    //   .toString('base64')
    //   .replace(/\+/g, '-')
    //   .replace(/\//g, '_')
    //   .replace(/=+$/, '');

    const { encodedString } = onEncodedString({ e });
    setEnteredCarNumber(encodedString);
    setOnSavedEnteredCarNumberCV103(encodedString);
    setOnSavedViewCarNumberCV103(e.target.value); // 🙏🙏🙏화면에 보이는 부분
  };
  // 👆 [TAG] 차량 번호 -----end

  const savedSelectedCheckPeriodCV103 = sessionStorage.getItem(
    'savedSelectedCheckPeriodCV103'
  ); // 🙏

  const [selectedCheckPeriod, setSelectedCheckPeriod] = useState('');

  const handleCheckPeriod = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCheckPeriod(e.target.value);
  };

  const checkPeriodOptions = [
    {
      value: savedSelectedCheckPeriodCV103 ? savedSelectedCheckPeriodCV103 : '',
      label: savedSelectedCheckPeriodCV103
        ? `${savedSelectedCheckPeriodCV103}일`
        : '기본(30일)',
    },
    { value: '1', label: '1일' },
    { value: '7', label: '7일' },
    { value: '15', label: '15일' },
    { value: '30', label: '30일' },
    { value: '60', label: '60일' },
    { value: '90', label: '90일' },
    { value: '180', label: '180일' },
  ];

  let onViewCarNumberCV103Foo2;
  let zooZoo2;

  if (permitCode === apartmentManagerCode) {
    useEffect(() => {
      onViewCarNumberCV103Foo2 = sessionStorage.getItem(
        'onViewCarNumberCV103Foo'
      );
      sessionStorage.setItem('zooZoo', ''); // 🍑🍑🍑

      // 👇 관리소장 권한시: 단지 인덱스 부여
      setApartmentIdx(apartmentRefIdx);

      if (apartmentIdx) {
        loadExistingData({
          setLoadedData,
          setLoading,
          apartmentIdx: Number(apartmentIdx),
          selectedContractStart,
          selectedContractEnd,
          significantFlagSign,
          unregisteredCarSign,
          enteredCarNumber, // 🍑🍑🍑
          selectedCheckPeriod,
        });
      }
    }, [selectedContractStart, selectedContractEnd, apartmentIdx]);
  }

  if (permitCode === headOfficerManagerCode) {
    useEffect(() => {
      onViewCarNumberCV103Foo2 = sessionStorage.getItem(
        'onViewCarNumberCV103Foo'
      );

      sessionStorage.setItem('zooZoo', ''); // 🍑🍑🍑

      if (apartmentIdx) {
        loadExistingData({
          setLoadedData,
          setLoading,
          apartmentIdx: Number(apartmentIdx),
          selectedContractStart,
          selectedContractEnd,
          significantFlagSign,
          unregisteredCarSign,
          enteredCarNumber, // 🍑🍑🍑
          selectedCheckPeriod,
        });
      }
    }, [selectedContractStart, selectedContractEnd, apartmentIdx]);
  }

  useEffect(() => {
    // 초기화되지 않은 값이 있는지 확인 후 기본값으로 설정
    // const startYear = calStartYear || startDate.getFullYear(); // 🍟
    // const startMonth = calStartMonth || startDate.getMonth(); // 🍟
    // const startDay = calStartDate || startDate.getDate(); // 🍟

    // const newStartDate = new Date(`${startYear}-${startMonth + 1}-${startDay}`); // 🍟

    // 👇----------------수정전
    const newStartDate = new Date(
      `${calStartYear}-${calStartMonth}-${calStartDate}`
    );
    // 👆----------------수정전
    setStartDate(newStartDate);

    // const endYear = calEndYear || endDate.getFullYear(); // 🍟
    // const endMonth = calEndMonth || endDate.getMonth(); // 🍟
    // const endDay = calEndDate || endDate.getDate(); // 🍟

    // const newEndDate = new Date(`${endYear}-${endMonth + 1}-${endDay}`); // 🍟
    // 👇----------------수정전
    const newEndDate = new Date(`${calEndYear}-${calEndMonth}-${calEndDate}`);
    // 👆----------------수정전
    setEndDate(newEndDate);

    zooZoo2 = sessionStorage.getItem('zooZoo');

    if (apartmentIdx) {
      loadExistingData({
        setLoadedData,
        setLoading,
        apartmentIdx: Number(apartmentIdx),
        selectedContractStart,
        selectedContractEnd,
        significantFlagSign,
        unregisteredCarSign,
        enteredCarNumber,
        selectedCheckPeriod: savedSelectedCheckPeriodCV103
          ? savedSelectedCheckPeriodCV103
          : selectedCheckPeriod,
        onSavedViewCarNumberCV103, // 화면에 보이는 인풋
      });
    }
  }, []);

  const pressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
        return alert('단지를 선택해주세요');
      }

      e.preventDefault(); // 기본 동작 중단
      console.log('Enter key pressed'); // 디버깅을 위한 로그

      zooZoo2 = sessionStorage.getItem('zooZoo');

      return loadExistingData({
        setLoadedData,
        setLoading,
        apartmentIdx: Number(apartmentIdx),
        selectedContractStart,
        selectedContractEnd,
        significantFlagSign,
        unregisteredCarSign,
        enteredCarNumber,
        selectedCheckPeriod: savedSelectedCheckPeriodCV103
          ? savedSelectedCheckPeriodCV103
          : selectedCheckPeriod,
        onSavedViewCarNumberCV103, // 화면에 보이는 인풋
      });
    }
  };

  if (loading) {
    return (
      <>
        <Background>
          <LoadingWrap>
            <LoadingGIF src="/images/loading.gif" />
          </LoadingWrap>
        </Background>
      </>
    );
  }

  zooZoo2 = sessionStorage.getItem('zooZoo');

  // ------------------
  const onMoveToPrintPage = () => {
    if (loadedData.length <= 0) {
      return alert('불러온 데이터가 없습니다');
    }

    const dataToSend = 'parking-info-printing';
    const originPart = 'CV103';

    // 👇 ----- 1 (미사용) -----
    // const dataArray = [1, 2, 3, 4];
    // const url = `/${dataToSend}?dataArray=${dataArray}`;
    // 👆 ----- 1 (미사용) -----

    // 👇 ----- 2 (미사용) -----
    // const dataArray = [
    //   { key1: 'value1', key2: 'value2' },
    //   { key3: 'value3', key4: 'value4' },
    // ];
    // const url = `/${dataToSend}?dataArray=${encodeURIComponent(
    //   JSON.stringify(dataArray)
    // )}`;
    // 👆 ----- 2 (미사용) -----

    // 👇 ----- 3 (미사용) -----
    // const dataArray = [1, 2, 3, 4];
    // const dataObject = { key1: 'value1', key2: 'value2' };
    // const url = `/${dataToSend}?dataArray=${encodeURIComponent(
    //   JSON.stringify(dataArray)
    // )}&dataObject=${encodeURIComponent(JSON.stringify(dataObject))}`;
    // 👆 ----- 3 (미사용) -----

    const dataObject = {
      apartmentName: apartmentName ? apartmentName : '',
      apartmentIdx: Number(apartmentIdx),
      selectedContractStart: selectedContractStart,
      selectedContractEnd: selectedContractEnd,
      significantFlagSign: significantFlagSign,
      unregisteredCarSign: unregisteredCarSign,
      enteredCarNumber: enteredCarNumber,
      selectedCheckPeriod: selectedCheckPeriod,
    };

    const url = `/${dataToSend}?dataObject=${encodeURIComponent(
      JSON.stringify(dataObject)
    )}`;

    const childWindow = window.open(
      url,
      '_blank',
      'width=820px, height=1000px, toolbar=no, location=no, status=no'
    );

    if (childWindow) {
      // childWindow.postMessage({ dataArray, dataObject }, window.origin);  -> 이전
      // childWindow.postMessage({ dataObject }, window.origin); -> 이전
      // childWindow.postMessage({ dataObject }); // 현재 적용됨 - 주석처리함
    }
  };
  // ------------------

  const onMoveToViolationPrintPage = () => {
    if (loadedData.length <= 0) {
      return alert('불러온 데이터가 없습니다');
    }

    const dataToSend = 'parking-Violation-printing';
    const originPart = 'CV103';

    const dataObject2 = {
      apartmentName: apartmentName ? apartmentName : '',
      apartmentIdx: Number(apartmentIdx),
      selectedContractStart: selectedContractStart,
      selectedContractEnd: selectedContractEnd,
      significantFlagSign: significantFlagSign,
      unregisteredCarSign: unregisteredCarSign,
      enteredCarNumber: enteredCarNumber,
      selectedCheckPeriod: selectedCheckPeriod,
      // violationListData: dataFoo ? dataFoo : '',
      imageText: imageText ? imageText : '',
      //
      setLoadedData: setLoadedData,
      setLoading: setLoadedData,
      // apartmentIdx: Number(apartmentIdx),
      // selectedContractStart: selectedContractStart,
      // selectedContractEnd: selectedContractEnd,
      // significantFlagSign: significantFlagSign,
      // unregisteredCarSign: unregisteredCarSign,
      // enteredCarNumber: enteredCarNumber,
      // selectedCheckPeriod: savedSelectedCheckPeriodCV103
      //   ? savedSelectedCheckPeriodCV103
      //   : selectedCheckPeriod,
      onSavedViewCarNumberCV103: onSavedViewCarNumberCV103,
    };

    const url = `/${dataToSend}?dataObject=${encodeURIComponent(
      JSON.stringify(dataObject2)
    )}`;

    const childWindow = window.open(
      url,
      '_blank',
      'width=820px, height=1000px, toolbar=no, location=no, status=no'
    );

    if (childWindow) {
      console.log('🥩🍠🍛🍜🦪🍣');
      // childWindow.postMessage({ dataArray, dataObject }, window.origin);  -> 이전
      // childWindow.postMessage({ dataObject2 }, window.origin); -> 이전
      // childWindow.postMessage({ dataObject2 }); // 현재 적용됨 - 주석처리함
    }
  };
  // ------------------

  // [MEMO] onExportCSVFile(): CSV파일로 내보내기
  function onExportCSVFile() {
    if (permitCode === headOfficerManagerCode) {
      gridOptionsOfficeManager.api.exportDataAsCsv();
    }

    if (permitCode === apartmentManagerCode) {
      gridOptionsApartmentManager.api.exportDataAsCsv();
    }
  }

  // -----
  /**
   * [MEMO] targetMonth:
   * Date객체의 월 인덱스는 0부터 시작하기 때문에, 데이터 피커에서 가져 온 달에서 1을 빼야 함
   */
  // 👇 데이터 피커에서 선택한 날짜
  const year = startDate.getFullYear().toString();
  const month = (startDate.getMonth() + 1).toString();
  const date = startDate.getDate().toString();

  const calYear = String(year).padStart(2, '0');
  const calMonth = String(month).padStart(2, '0');
  const calDate = String(date).padStart(2, '0');

  const targetYear = Number(calYear);
  const targetMonth = Number(calMonth) - 1;

  const filteredDate = new Date(targetYear, targetMonth);

  const lastDateOfMonth = new Date(
    filteredDate.getFullYear(),
    filteredDate.getMonth() + 1,
    0
  ).getDate();

  console.log('🍠targetYear: ', targetYear);
  console.log('🍠targetMonth: ', targetMonth);
  console.log('🍠lastDateOfMonth: ', lastDateOfMonth);

  return (
    <>
      {permitCode === headOfficerManagerCode && onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListData={apartmentListData}
          setApartmentListData={setApartmentListData}
          apartmentIdx={apartmentIdx}
          setApartmentIdx={setApartmentIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}

      <Title>차량 주차 관리</Title>
      <DatePickerSection>
        <DatePickerBoxWrap>
          <DatePickerBox>
            <p>시작일</p>
            <div>
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(date: React.SetStateAction<any>) =>
                  setStartDate(date)
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </DatePickerBox>
          <DatePickerBox>
            <p className="end-date">종료일</p>
            <div>
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                selected={endDate}
                onChange={(date: React.SetStateAction<any>) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                // maxDate={addDays(new Date(), 5)}
                maxDate={addDays(startDate, 30)}
                // highlightDates={[subDays(new Date(), 7), addDays(new Date(), 7)]}
                // highlightDates={[addDays(startDate, 30)]}
                // excludeDates={[new Date(), subDays(new Date(), 1)]}
                // excludeDates={[startDate, subDays(startDate, 5)]}
                // placeholderText="요일을 선택하세요"
              />
            </div>
          </DatePickerBox>

          {permitCode === headOfficerManagerCode && (
            <div
              style={{
                display: 'flex',
                marginLeft: '5px',
              }}
            >
              <Button
                label="단지 선택"
                onClick={() =>
                  onApartmentSerialNumberList({
                    apartmentListData,
                    setApartmentListData,
                    setOnModal,
                  })
                }
              />

              <ApartmentNameBox>
                <ApartmentIdx>{apartmentIdx}</ApartmentIdx>
                <div>{apartmentName}</div>
              </ApartmentNameBox>
            </div>
          )}

          <div style={{ display: 'flex', marginLeft: '5px' }}>
            <Button
              label="차량 정보 불러오기"
              onClick={() =>
                loadExistingData({
                  setLoadedData,
                  setLoading,
                  apartmentIdx: Number(apartmentIdx),
                  selectedContractStart,
                  selectedContractEnd,
                  significantFlagSign,
                  unregisteredCarSign,
                  enteredCarNumber,
                  selectedCheckPeriod: savedSelectedCheckPeriodCV103
                    ? savedSelectedCheckPeriodCV103
                    : selectedCheckPeriod,
                  onSavedViewCarNumberCV103, // 화면에 보이는 인풋
                })
              }
            />
          </div>
        </DatePickerBoxWrap>

        <div>
          <PrintBtn onClick={() => onMoveToViolationPrintPage()}>
            위반 차량 인쇄
          </PrintBtn>
          <PrintBtn
            onClick={() => onMoveToPrintPage()}
            style={{ marginLeft: '5px' }}
          >
            주차 정보 인쇄
          </PrintBtn>
          <PrintBtn
            style={{ marginLeft: '5px' }}
            onClick={() => onExportCSVFile()}
          >
            주차 정보 내보내기(엑셀)
          </PrintBtn>
          {/* <PrintBtn onClick={() => onMoveToPrintPage()}>주차 정보 인쇄</PrintBtn> */}
        </div>
      </DatePickerSection>

      <Section>
        <Bar />
        <LoadInfoSection>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SelectStyle onChange={handleCheckPeriod}>
              {checkPeriodOptions.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </SelectStyle>

            <div>
              <input
                type="checkbox"
                id={'check-valid-Significant'}
                checked={checkedValidSignificantFlag}
                onChange={() => switchCheckedValidSignificantFlag()}
              />
              <LabelStyle htmlFor={'check-valid-Significant'}>
                위반차량
              </LabelStyle>
            </div>

            <div>
              <input
                type="checkbox"
                id={'check-unregistered'}
                checked={checkedUnregisteredCar}
                onChange={() => switchCheckedUnregisteredCar()}
              />
              <LabelStyle htmlFor={'check-unregistered'}>미등록차량</LabelStyle>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '4px',
              }}
            >
              {/* <div style={{ fontSize: '13px' }}>차량 번호</div> */}
              <input
                style={{
                  height: '30px',
                  border: 'solid 1px #ededed',
                  borderRadius: '5px',
                  marginLeft: '5px',
                }}
                placeholder="차량 번호/소유주/소유주 연락처"
                defaultValue={onSavedViewCarNumberCV103}
                onChange={onEnteredCarNumber}
                onKeyPress={pressEnter}
              />
            </div>
          </div>
        </LoadInfoSection>
      </Section>

      <GridSection>
        <Grid
          data={loadedData}
          imageText={imageText}
          setImageText={setImageText}
        />
      </GridSection>
    </>
  );
};

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  /* opacity: 50%; */
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF};

  /* opacity: 50%; */
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const GridSection = styled.div`
  height: ${({ theme }) => theme.gridStyle.height};
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  /* width: 80px; */
  cursor: pointer;
`;

const DatePickerSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-size: 13px;
`;

const DatePickerBoxWrap = styled.div`
  display: flex;
`;

const DatePickerBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  p {
    /* width: 100%; */
    margin-right: 5px;

    &.end-date {
      margin-left: 10px;
    }
  }
`;

const SelectStyle = styled.select`
  padding: 0 5px;
  text-align: center;
  /* width: 180px; */
  width: 100px;
  height: 30px;
  background-color: white;
  color: ${({ theme }) => theme.fontColor.black};
  border: 1px solid ${({ theme }) => theme.backgroundColor.gray};
  font-size: 13px;
  border-radius: 5px;

  /* 기본 스타일 제거 */
  /* appearance: none;  */
`;

const LabelStyle = styled.label`
  font-size: 13px;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;

const PrintBtn = styled.button`
  ${({ theme }) => theme.printBtnStyle};
`;

const Section = styled.div`
  /* */
`;

const LoadInfoSection = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-size: 13px;
`;
