import React, { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import * as Sentry from "@sentry/react";

interface onChangePhoneNumberProps {
  userName: string;
  regNum: string;
  oldPhoneNumber?: string;
  currentPhoneNumber: string;
  verifiedCode: string;
  // recordListData: any[];
  // setRecordListData: Dispatch<SetStateAction<any[]>>;
  // setLoading: Dispatch<SetStateAction<boolean>>;
}

export const onChangePhoneNumber = async ({
  userName,
  regNum,
  oldPhoneNumber,
  currentPhoneNumber,
  verifiedCode,
}: onChangePhoneNumberProps) => {
  if (!userName) {
    return alert('이름을 입력해주세요');
  } else if (!currentPhoneNumber) {
    return alert('변경할 전화번호를 입력해주세요');
  }

  await axios
    .post(
      // https://apihr.mmigroup.co.kr:9020/common/logincheck/changenumber?lang=ko&corpId=MM
      `${process.env.COMMON_URL}/common/logincheck/changenumber?lang=ko&corpId=MM`,
      {
        UserName: `${userName}`,
        RegNumber: '0000000',
        OldNumber: `${oldPhoneNumber}`,
        Number: `${currentPhoneNumber}`,
        AuthMessage: `${verifiedCode}`,
      }
    )
    .then(res => {
      console.log(
        '전화번호 변경 확인 - API확인',
        `${process.env.COMMON_URL}/common/logincheck/changenumber?lang=ko&corpId=MM`
      );

      try {
        //console.log('PhoneNo Change - RES.DATA' , res.data);
        if (res.data.ErrorCode === 0) {
          if(res.data.Data[0].ResultCode === 0) {
            alert('전화번호가 변경되었습니다');
            window.close();
          } else {
            Sentry.captureMessage(`전화번호 변경 실패 : ${res.data.Data[0].ResultMessage} - ResCode [${res.data.Data[0].ResultCode}]`);
            return alert(`전화번호 변경 실패\n\n- ${res.data.Data[0].ResultMessage} - ResCode [${res.data.Data[0].ResultCode}]`);
          }
        } else {
          Sentry.captureMessage(`전화번호 변경 실패 : ${res.data.ErrorMsg} - ErrorCode [${res.data.ErrorCode}]`);
          return alert(`전화번호 변경 실패\n\n-${res.data.ErrorMsg} - ErrorCode [${res.data.ErrorCode}]`);
        }
      } catch (e) {
        Sentry.captureMessage(`전화번호 변경 실패 : ${res.data.ErrorMsg} - ExceptionCode [${res.data.ErrorCode}]`);
        Sentry.captureException(e);
        //console.log('PhoneNo Change ERROR - RES.DATA' , res.data);
        return alert(`전화번호 변경 실패\n\n-${res.data.ErrorMsg} - ExceptionCode [${res.data.ErrorCode}]`);
      }
    });
};