import React from 'react';
import styled from 'styled-components';

import { ModalContents } from './ModalContents';

interface DetailInfoModalProps {
  closeDetailInfoModal: React.MouseEventHandler<HTMLDivElement> | undefined;
  detailInfoData: any[];
  detailInfoLoading: boolean;
  image: string[];
}

export const DetailInfoModal = ({
  closeDetailInfoModal,
  detailInfoData,
  detailInfoLoading,
  image,
}: DetailInfoModalProps) => {
  if (detailInfoLoading) {
    return (
      <Background onClick={closeDetailInfoModal}>
        <LoadingGIF src="/images/loading.gif" />
      </Background>
    );
  }

  return (
    <Background onClick={closeDetailInfoModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents detailInfoData={detailInfoData} image={image} />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF}
`;
