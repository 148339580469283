import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface onLoadStatusCodeProps {
  setScheduleStatusCodeList: Dispatch<SetStateAction<any>>;
}

export const onLoadStatusCode = async ({
  setScheduleStatusCodeList,
}: onLoadStatusCodeProps) => {
  //
  const token = sessionStorage.getItem('token');

  await axios
    .get(`${process.env.COMMON_URL}/common/refattendstatus`, {
      headers: { tokenId: token },
    })
    .then(res => {
      try {
        setScheduleStatusCodeList(res.data.Data);
        //console.log('코드상태res.data.Data:', res.data.Data);
      } catch (e) {
        //
      }
    });
};
